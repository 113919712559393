import {Actions, Mutations} from "@/store/enum/StoreEnums";

export default {
    state: () => ({
        breadcrumbs: {}
    }),
    getters: {
        getBreadcrumbs(state) {
            return state.breadcrumbs;
        },

        pageBreadcrumbPath(state) {
            return state.breadcrumbs.pageBreadcrumbPath;
        },

        pageTitle(state) {
            return state.breadcrumbs.title;
        }
    },
    actions: {
        [Actions.SET_BREADCRUMB_ACTION](state, payload) {
            state.commit(Mutations.SET_BREADCRUMB_MUTATION, payload);
        }
    },
    mutations: {
        [Mutations.SET_BREADCRUMB_MUTATION](state, payload) {
            state.breadcrumbs = payload;
        }
    }
};