import objectPath from "object-path";
import merge from "deepmerge";
import layoutConfig from "@/core/config/DefaultLayoutConfig";
import {Mutations} from "@/store/enum/StoreEnums";

export default {
    state: () => ({
        config: layoutConfig,
        initial: layoutConfig
    }),
    getters: {
        /**
         * Get config from layout config
         * @param state
         * @returns {function(path, defaultValue): *}
         */
        layoutConfig: state => (path, defaultValue) => {
            return objectPath.get(state.config, path, defaultValue);
        }
    },
    actions: {},
    mutations: {
        [Mutations.SET_LAYOUT_CONFIG](state, payload) {
            state.config = payload;
        },
        [Mutations.RESET_LAYOUT_CONFIG](state) {
            state.config = Object.assign({}, state.initial);
        },
        [Mutations.OVERRIDE_LAYOUT_CONFIG](state) {
            state.config = state.initial = Object.assign(
                {},
                state.initial,
                JSON.parse(localStorage.getItem("config"))
            );
        },
        [Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
            state.config = merge(state.config, payload);
        }
    }
};
