import store from "@/store";
import {Actions} from "@/store/enum/StoreEnums";

/**
 * Sets current page breadcrumbs
 * @param {string} pageTitle Current page title
 * @param {Array<string>} breadcrumbs Current page breadcrumbs
 */
export const setCurrentPageBreadcrumbs = (pageTitle, breadcrumbs) => {
    store.dispatch(Actions.SET_BREADCRUMB_ACTION, {
        title: pageTitle,
        pageBreadcrumbPath: breadcrumbs,
    });
};

/**
 * Sets current page breadcrumbs
 * @param {string} title Current page title name
 */
export const setCurrentPageTitle = (title) => {
    store.dispatch(Actions.SET_BREADCRUMB_ACTION, {
        title: title,
    });
};
