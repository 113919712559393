class Validation {
    static app;
    static vm;
    static rules;

    static init(app) {
        this.app = app;
        this.vm = app.config.globalProperties;
        this.vm.$validation = Validation;

        this.rules = {
            required: {
                required: true,
                message: this.vm.$t('validation.required'),
            },
            requiredBlur: {
                required: true,
                message: this.vm.$t('validation.required'),
                trigger: ['blur']
            },
            email: {
                type: 'email',
                message: this.vm.$t('validation.email'),
                trigger: ['blur', 'change']
            },
            number: {
                type: 'number',
                message: this.vm.$t('validation.number')
            }
        }
    }

    static getMessage(rules){
        return rules.map((rule) => {
            return this.rules[rule];
        });
    }
}

export default Validation;
