const AUTH_PREFIX = "auth.";

export const get = (key) => {
  return window.localStorage.getItem(AUTH_PREFIX + key);
};

export const save = (key, token) => {
  window.localStorage.setItem(AUTH_PREFIX + key, token);
};

export const destroy = (key) => {
  window.localStorage.removeItem(AUTH_PREFIX + key);
};

export default { get, save, destroy };
