import {Modal} from "bootstrap";

const hideModal = (modalEl) => {
    if (!modalEl) {
        return;
    }

    const myModal = Modal.getInstance(modalEl);
    myModal.hide();
};

const showModal = (modalEl) => {
    if (!modalEl) {
        return;
    }

    const myModal = Modal.getInstance(modalEl) || new Modal(modalEl);
    myModal.show();
};

const removeModalBackdrop = () => {
    if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
        document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
            item.remove();
        });
    }
};

export {removeModalBackdrop, showModal, hideModal };
