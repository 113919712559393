import {Actions, Mutations} from "@/store/enum/StoreEnums";

export default {
    state: () => ({
        classes: {}
    }),
    getters: {
        getClasses: state => position => {
            if (typeof position !== "undefined") {
                return state.classes[position];
            }
            return state.classes;
        }
    },
    actions: {
        [Actions.ADD_BODY_CLASSNAME](context, className) {
            document.body.classList.add(className);
        },
        [Actions.REMOVE_BODY_CLASSNAME](context, className) {
            document.body.classList.remove(className);
        },
        [Actions.ADD_BODY_ATTRIBUTE](state, payload) {
            const { qulifiedName, value } = payload;
            document.body.setAttribute(qulifiedName, value);
        },
        [Actions.REMOVE_BODY_ATTRIBUTE](state, payload) {
            const { qulifiedName } = payload;
            document.body.removeAttribute(qulifiedName);
        },
        [Actions.ADD_CLASSNAME](context, payload) {
            context.commit(Mutations.SET_CLASSNAME_BY_POSITION, payload);
        }
    },
    mutations: {
        [Mutations.SET_CLASSNAME_BY_POSITION](state, payload) {
            const { position, className } = payload;
            if (!state.classes[position]) {
                state.classes[position] = [];
            }
            state.classes[position].push(className);
        }
    }
}